import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { WARM_GREY, ThemeProvider, LangStringsProvider, LayoutsProvider, PageContextDefinition, EmbedComponentsProvider, useEmbedComponents, LayoutShell, SiteFooterLayout, UiConfig, UiProvider } from '@shapeable/ui';

import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { LayoutStyles, MARINE_GREEN, themeOverrides } from '../theme';
// import { SiteHeaderLayout } from '../entities/site-header-layout';

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextDefinition;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});

// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const embedComponents = useEmbedComponents();
  
  const config: UiConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    theme: themeOverrides,
    header: {
      variant: 'light',
      activeColor: MARINE_GREEN,
    },
    menuBar: {
      color: 'strong',
      buttonColor: 'strong',
    },
  }

  return (
    <UiProvider value={config}>
      <My.Container
        className={className}
        includeTooltip
        tooltipProps={{
          backgroundColor: '#FFFFFF',
        }}
      >
        <My.Body>
          {children}
        </My.Body>
        <My.Footer backgroundColor={WARM_GREY} />
      </My.Container>
    </UiProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

